import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import PageContent from "../components/PageContent";
import BlogEntryPreview from "../components/BlogEntryPreview"
import TagsNavigation from "../components/TagsNavigation";
import BackToBlog from "../components/BackToBlog";


class BlogTag extends React.Component {
  render() {
    const pageName = "blog";
    const { data } = this.props;
    const pageTitle = `Tagged with: ${this.props.location.pathname.split("/").splice(-1).pop()}`;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={pageTitle} pageName={pageName}>
        <SEO
          title={pageTitle}
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <PageContent>
          <div className="blog-tag">
            {posts.map(({ node }) => {
              return (
                <BlogEntryPreview key={node.fields.slug} node={node} />
              )
            })}
            <TagsNavigation />
            <BackToBlog />
          </div>
        </PageContent>
      </Layout>
    )
  }
}

export default BlogTag

export const pageQuery = graphql`
  query BlogPostsByTag($tag: String!){
    allMarkdownRemark(filter: { collection: { eq: "blog" }, frontmatter: { tags: { in: [$tag] } } },
                      sort: { fields: [frontmatter___date], order: DESC }) {
      ...BlogEntryPreview
    }
  }
`
